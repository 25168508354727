import React, { useState, useEffect } from 'react';

const SpotlightSearchComponent: React.FC<{
    placeholder: string,
    searchValues: string[],
    show: boolean,
    handleSearchValueClicked: (value: string) => void,
}> = ({ placeholder, searchValues, show, handleSearchValueClicked }) => {
    const [inputValue, setInputValue] = useState('');
    const [matches, setMatches] = useState<string[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    const handleSearch = (value: string) => {
        if (!value) {
            setMatches([]);
            return;
        }
        const matchedValues = searchValues.filter((searchValue) => searchValue.toLowerCase().includes(value.toLowerCase())) || [] as string[];
        setMatches(matchedValues);
        setSelectedIndex(-1); // Reset the selected index when search changes
    };

    useEffect(() => {
        if (show) {
            document.getElementById('spotlight-search-input')?.focus();
        }
    }, [show]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!show) return;

            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => (prevIndex + 1) % matches.length);
                event.preventDefault();
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => (prevIndex - 1 + matches.length) % matches.length);
                event.preventDefault();
            } else if (event.key === 'Enter') {
                handleSearchValueClicked(matches[selectedIndex]);
            }
        };
        window.removeEventListener('keydown', handleKeyDown);
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, matches, selectedIndex]);

    // Only render when there are no conversations
    if (!show) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            boxSizing: 'border-box',
            overflow: 'hidden',
        }}>
            <div style={styles.searchBox}>
                <input
                    id="spotlight-search-input"
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        handleSearch(e.target.value);
                    }}
                    placeholder={placeholder}
                    style={styles.input}
                />
                <ul style={styles.matchList}>
                    {matches.map((match, index) => (
                        <li
                            key={index}
                            style={{
                                ...styles.matchItem,
                                backgroundColor: index === selectedIndex ? '#ddd' : 'transparent'
                            }}
                            onClick={() => {
                                console.log("Selected match: ", match);
                                handleSearchValueClicked(match);
                            }}
                        >
                            {match}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

// Updated styles for overlay, search box, and matches
const styles = {
    searchBox: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '10px 10px',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
        alignItems: 'center',
        width: '60%',
        maxWidth: '600px',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        // marginBottom: '10px',
        boxSizing: 'border-box' as const,
    },
    matchList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    matchItem: {
        padding: '8px',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
    },
};

export default SpotlightSearchComponent;
