import './index.css';
import LoginComponent from './components/login-component';
import { DashboardComponent } from './components/dashboard-component';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './components/error-page';
import { SignUpComponent } from './components/signup-page-component';
import { ForgotPasswordComponent } from './components/forgot-password-component';
import { TermsOfServiceComponent } from './components/terms-of-service-component';
import { ProfileComponent } from './components/profile-component';
import SplashPage from './components/splash-page';
import { CompanyComponent } from './components/company-component';
import { FeaturesComponent } from './components/features-component';
import { ChangelogComponent } from './components/change-log-component';
import { PrivacyPolicyComponent } from './components/privacy-policy-component';
import { MainBackground } from './components/main-background';
import { useEffect, useRef, useState } from 'react';
import { Conversation } from './api/models/Conversation';
import { createCheckoutSession, getUser } from './api/thunks/users';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { TryIt } from './components/try-it-component';
import { createConversation, getAllConversations, getAllSupportedTickers, getConversation, watchConversation } from './api/thunks/conversations';
import { setNotification } from './store/notifications-reducer';
import { PricingComponent } from './components/pricing-component';
import { ComparisonComponent } from './components/comparison-component';
import SpotlightSearchOverlay from './components/spotlight-search-component';
import ImageModal, { ImgModalState } from './components/image-modal';
import { CodeModal } from './components/code-modal';
import { BundledLanguage, BundledTheme, createHighlighter, HighlighterGeneric } from "shiki";

export function App() {
  const dispatch = useAppDispatch();
  const credentials = useAppSelector((state) => state.user.credentials);

  const [imgModalState, setImgModalState] = useState<ImgModalState>({
    isImageModalOpen: false,
    modalImageSrc: '',
  });

  const [highlighter, setHighlighter] = useState<HighlighterGeneric<BundledLanguage, BundledTheme>>()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCode, setCurrentCode] = useState<string | null>(null);

  const [convo, setConvo] = useState<Conversation | undefined>();
  const [allConvos, setAllConvos] = useState<Conversation[]>([]);
  const [supportedTickers, setSupportedTickers] = useState<string[]>([]);
  const [shouldShowSpotlight, setShouldShowSpotlight] = useState<boolean>(allConvos.length == 0);
  const websocket = useRef<WebSocket>()
  let wsPongTimeout: NodeJS.Timeout;

  const handleSearchValueClicked = async (value: string) => {
    if (!credentials) return;
    const res = await createConversation({ ticker: value }, credentials.accessToken, dispatch);
    if (res?.id) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('conversationId', res.id);
      window.location.href = '/dashboard' + '?' + queryParams.toString();
    }
  }

  const openCodeModal = (code: string) => {
    setCurrentCode(code);
    setIsModalOpen(true);
  };

  const closeCodeModal = () => {
    setCurrentCode(null);
    setIsModalOpen(false);
  };

  const router = createBrowserRouter([
    {
      path: "/health",
      element: <div>Healthy</div>,
    },
    {
      path: "/",
      element: <SplashPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/company",
      element: <CompanyComponent />
    },
    {
      path: "/changelog",
      element: <ChangelogComponent />
    },
    {
      path: '/terms-of-service',
      element: <TermsOfServiceComponent></TermsOfServiceComponent>,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyComponent></PrivacyPolicyComponent>,
    },
    {
      path: "/profile",
      element:
        <MainBackground convos={allConvos} setConvos={setAllConvos} currConvo={convo} supportedTickers={supportedTickers} showMenu={true}>
          <ProfileComponent />
        </MainBackground>,
    },
    {
      path: '/signup',
      element: <SignUpComponent></SignUpComponent>,
      errorElement: <ErrorPage />,
    },
    {
      path: '/password-reset',
      element: <ForgotPasswordComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login",
      element: <LoginComponent></LoginComponent>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/dashboard",
      element:
        <>
          <ImageModal
            imgName=''
            isOpen={imgModalState.isImageModalOpen}
            imageSrc={imgModalState.modalImageSrc}
            onClose={() => setImgModalState({ ...imgModalState, isImageModalOpen: false })}
          />
          {currentCode && (
            <CodeModal
              isOpen={isModalOpen}
              code={currentCode}
              onClose={closeCodeModal}
              highlighter={highlighter}
              language='python'
              openCodeModal={openCodeModal}
            />
          )}
          <SpotlightSearchOverlay placeholder='Search companies by ticker...' searchValues={supportedTickers} handleSearchValueClicked={handleSearchValueClicked} show={shouldShowSpotlight} />
          <MainBackground convos={allConvos} setConvos={setAllConvos} currConvo={convo} supportedTickers={supportedTickers} showMenu={true}>
            <DashboardComponent openCodeModal={openCodeModal} setImgModalState={setImgModalState} convo={convo} setConvo={setConvo} />
          </MainBackground>
        </>,
      errorElement: <ErrorPage />,
    },
    {
      path: '/try-it',
      element: <TryIt />
    },
    {
      path: '/pricing',
      element: <PricingComponent />
    },
    {
      path: '/pq-v-chatgpt',
      element:
        <MainBackground convos={allConvos} setConvos={setAllConvos} currConvo={convo} supportedTickers={supportedTickers} showMenu={false}>
          <ComparisonComponent />
        </MainBackground>,
    }
  ]);

  const snakeCaseToTitleCase = (str: string) => {
    return str.replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
  };
  const camelCaseToTitleCase = (str: string) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
  };

  const setupWebsocket = () => {
    if (websocket.current === undefined || websocket.current?.readyState !== WebSocket.OPEN) {
      websocket.current = new WebSocket(process.env.REACT_APP_API_URL + "/v1/events");
      websocket.current.onopen = () => {
        console.log("websocket connected, sending auth message");
        if (!websocket.current || !credentials || websocket.current.readyState !== WebSocket.OPEN) {
          console.log("no websocket or no credentials ", websocket.current, credentials);
          return;
        }

        let creds = JSON.parse(localStorage.getItem('credentials') || '{}');

        websocket.current.send(JSON.stringify({ type: "authorization", data: creds.accessToken, }));

        wsPongTimeout = setInterval(() => {
          console.log('ponging')
          websocket.current?.send(JSON.stringify({ type: "pong" }));
        }, 500);
      };

      websocket.current.onmessage = async (event) => {
        const msg = JSON.parse(event.data);

        if (msg.type === 'ping') {
          return;
        }

        if (!credentials) {
          return;
        }

        console.log("websocket message", msg);
        switch (msg.type) {
          case "run_completed":
            console.log('received conversation updated', msg);
            const updatedConvo = await getConversation(msg.data.conversation_id, credentials.accessToken);
            setConvo(updatedConvo);
            break;
          case "free_credit_used":
            console.log('received free credit used');
            dispatch(getUser());
            break;
          case "function_call":
            const funcName = msg.data.function_call_details.name;
            let funcDesc = snakeCaseToTitleCase(funcName);
            let jsonArgs = JSON.parse(msg.data.function_call_details.args);
            if (funcName === 'get_financial_ratio') {
              funcDesc = `Computing ${jsonArgs.fiscalPeriod}-${jsonArgs.fiscalYear} ${snakeCaseToTitleCase(jsonArgs.ratio)}`;
            } else if (funcName === 'get_earnings_statement_item') {
              funcDesc = `Looking up earnings data ${camelCaseToTitleCase(jsonArgs.item)} ${jsonArgs.fiscalPeriod}-${jsonArgs.fiscalYear}`;
            } else if (funcName === 'get_schema') {
              funcDesc = `Looking up ${snakeCaseToTitleCase(jsonArgs.schemaName)}`;
            } else if (funcName === 'get_segments_or_kpi_info') {
              funcDesc = `Getting ${snakeCaseToTitleCase(jsonArgs.segmentOrKpiName)} ${jsonArgs.fiscalPeriod}-${jsonArgs.fiscalYear}`;
            }

            dispatch(setNotification({
              message: funcDesc,
              type: 'info'
            }));
            break;
        }
      };

      websocket.current.onclose = () => {
        console.log("websocket closed, reconnecting in 5 seconds");
        clearTimeout(wsPongTimeout);
        setTimeout(() => {
          websocket.current = undefined;
          clearTimeout(wsPongTimeout);
          setupWebsocket();
        }, 5000);
      }
    }
  }

  useEffect(() => {
    if (!credentials) return;

    localStorage.setItem('credentials', JSON.stringify(credentials));

    setupWebsocket();
    dispatch(getUser());
    const query = new URLSearchParams(window.location.search);
    const conversationId = query.get('conversationId');

    if (query.get("redirect") === "checkout" && process.env.REACT_APP_PQ_PREMIUM_PRICE_ID !== undefined) {
      dispatch(createCheckoutSession({ productCode: process.env.REACT_APP_PQ_PREMIUM_PRICE_ID })).then((checkoutRes) => {
        console.log('checkoutRes ', checkoutRes)
        if (checkoutRes.meta.requestStatus === 'fulfilled') {
            window.location.href = (checkoutRes.payload as any).redirectUrl;
        } else {
            alert('Failed to create checkout session');
            return
        }
      }).catch((err) => {
        console.log('err ', err);
        alert('Failed to create checkout session');
        return
      })

      return;
    }

    getAllConversations(credentials.accessToken).then((res) => {
      setAllConvos(res);
      if (!conversationId && res.length > 0) {
        query.set('conversationId', res[0].id);
        window.location.href = window.location.pathname + '?' + query.toString();
      }
    }).catch((err) => {
      dispatch(setNotification({
        message: 'Failed to get conversations',
        type: 'error'
      }));
    });

    if (conversationId && window.location.href.includes('dashboard')) {
      getConversation(conversationId, credentials.accessToken).then((res) => {
        setConvo(res);
        watchConversation(res.ticker, credentials.accessToken).catch((err) => {
          dispatch(setNotification({
            message: 'Failed to watch. Message updates may not appear',
            type: 'error'
          }));
        });
      }).catch((err) => {
        dispatch(setNotification({
          message: 'Failed to get conversation',
          type: 'error'
        }));
      });
    }

    getAllSupportedTickers(credentials.accessToken).then((res) => {
      setSupportedTickers(res);
    }).catch((err) => {
      dispatch(setNotification({
        message: 'Failed to get supported tickers',
        type: 'error'
      }));
    });

  }, [credentials])

  useEffect(() => {
    setShouldShowSpotlight(allConvos.length == 0);
  }, [allConvos])

  useEffect(() => {
    createHighlighter({ themes: ["github-dark"], langs: ["python"] }).then((h) => {
      setHighlighter(h);
    });

    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === " " || e.key === 's')) {
        e.preventDefault();
        setShouldShowSpotlight((prev) => !prev)
      }

      if (e.key === 'Escape' && shouldShowSpotlight) {
        setShouldShowSpotlight(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <RouterProvider router={router} />
  );
}

