import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { createConversation, deleteConversation, getAllConversations, getConversation } from "../api/thunks/conversations";
import { useNavigate } from "react-router-dom";
import { Conversation } from "../api/models/Conversation";

export const ConversationListComponent = ({ convos, currConvo, supportedTickers, setConvos }:
    {
        convos: Conversation[];
        setConvos: (convos: Conversation[]) => void;
        currConvo: Conversation | undefined;
        supportedTickers: string[]
    }) => {

    const dispatch = useAppDispatch();
    const credentials = useAppSelector((state) => state.user.credentials);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [ticker, setTicker] = useState<string>(supportedTickers[0]);
    const navigate = useNavigate();

    const handleCreateConversation = async () => {
        if (!credentials) return;
        const res = await createConversation({ ticker: ticker }, credentials.accessToken, dispatch);
        if (res?.id) {
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set('conversationId', res.id);
            queryParams.delete('transcriptFiscalDateEnding');
            queryParams.delete('earningsFiscalDateEnding');
            window.location.href = '/dashboard' + '?' + queryParams.toString();
        }
        setIsEditing(false);
    }

    useEffect(() => {
        if (ticker === undefined && supportedTickers.length > 0) {
            setTicker(supportedTickers[0]);
        }
    }, [ticker, supportedTickers]);

    return (
        <div className="w-64 mr-4">
            <div className="font-semibold text-lg mb-2">Conversations</div>

            <div className="overflow-auto max-h-64">
                {convos.map((convo) => {
                    return (
                        <div key={convo.id} className={`flex items-center justify-between p-2 border-b cursor-pointer ${convo.ticker === currConvo?.ticker ? 'bg-gray-200' : 'hover:bg-gray-100'}`} onClick={(e) => {
                            e.stopPropagation();
                            console.log('navigating');
                            if (!convo.ticker) return;
                            const queryParams = new URLSearchParams(window.location.search);
                            queryParams.set('conversationId', convo.id);
                            navigate('/dashboard' + '?' + queryParams.toString());
                            navigate(0);
                        }}>
                            <div className="truncate">{convo.name}</div>
                            {/* delete button */}
                            <div onClick={async (e : any) => {
                                e.stopPropagation();
                                if (!convo.id || !credentials) return;
                                await deleteConversation(convo.id, credentials.accessToken, dispatch);
                                const updated = await getAllConversations(credentials.accessToken);
                                if (updated.length > 0) {
                                    const queryParams = new URLSearchParams(window.location.search);
                                    queryParams.set('conversationId', updated[0].id);
                                    window.location.href = '/dashboard' + '?' + queryParams.toString();
                                    return;
                                }
                                setConvos(updated);
                            }} className="flex items-center cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-600 hover:text-red-800">
                                    <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    );
                })}
            </div>

            {isEditing && (
                <>
                    <select className="block w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500 mt-2"
                        onChange={(e) => setTicker(e.target.value)}
                        defaultValue={ticker}>
                        {supportedTickers.map((ticker) => {
                            return (
                                <option key={ticker} value={ticker}>{ticker}</option>
                            );
                        })}
                    </select>

                    <button onClick={handleCreateConversation} className="mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2">
                        Create
                    </button>
                    <button onClick={() => setIsEditing(false)} className="mb-2 bg-red-500 ml-2 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2">
                        Cancel
                    </button>
                </>
            )}

            {!isEditing && (
                <button onClick={() => setIsEditing(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 mb-2">
                    + New
                </button>
            )}
        </div>
    )
}
