import { useNavigate } from "react-router-dom"
import { MainBackground } from "./main-background"
import { funnelRoutes } from "../constants/constants";

export const PricingComponent = () => {
    document.title = "Pricing | PocketQuant";
    const navigate = useNavigate();
    const excludedHrefs = ['/pricing'];
    const localNavigation = [...funnelRoutes.filter((x) => !excludedHrefs.includes(x.href)) ];
    return (
        <>
            <header className="absolute inset-x-0 p-6 lg:px-8 top-0 z-50">
                <nav className="flex items-center justify-center" aria-label="Global">
                    <div className="lg:flex lg:gap-x-12">
                        {localNavigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                </nav>
            </header>
            <MainBackground convos={[]} currConvo={undefined} setConvos={() => { }} showMenu={false} supportedTickers={[]}>

                <div className="max-w-6xl mx-auto py-12">
                    {/* Header */}
                    <div className="text-center mb-10">
                        <h1 className="text-3xl md:text-4xl font-bold mb-4">Compare plans</h1>
                    </div>

                    {/* Pricing Cards */}
                    <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-8">

                        {/* Free Plan */}
                        <div className="border rounded-lg shadow-lg p-8 w-full md:w-80 flex flex-col justify-between">
                            <div>
                                <h2 className="text-xl font-semibold mb-4">Free</h2>
                                <div className="text-4xl font-bold mb-4">$0<span className="text-sm font-normal">USD/month</span></div>
                                <ul className="space-y-4 mb-6">
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 5 copilot prompts</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 2nd Most recent earnings report</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 2nd Most recent transcript</li>
                                </ul>
                            </div>
                            <button onClick={() => navigate('/signup')} className="w-full bg-orange-500 text-white py-2 rounded-lg mt-6">Get Started</button>
                        </div>

                        {/* Premium Plan */}
                        <div className="border rounded-lg shadow-lg p-8 w-full md:w-80 bg-orange-50 flex flex-col justify-between">
                            <div>
                                <h2 className="text-xl font-semibold mb-4">Premium <span className="text-sm font-medium text-orange-600">(Most popular)</span></h2>
                                <div className="text-4xl font-bold mb-4">$20<span className="text-sm font-normal">USD/month</span></div>
                                <ul className="space-y-4 mb-6">
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 100 monthly copilot prompts</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Earnings reports since 2009</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Transcripts since 2021</li>
                                </ul>
                            </div>
                            <button onClick={() => navigate('/signup?redirect=checkout')} className="w-full bg-orange-500 text-white py-2 rounded-lg mt-6">Buy plan</button>
                        </div>

                        {/* Pro Plan */}
                        <div className="border rounded-lg shadow-lg p-8 w-full md:w-80 flex flex-col justify-between">
                            <div>
                                <h2 className="text-xl font-semibold mb-4">Pro (coming soon)</h2>
                                <div className="text-4xl font-bold mb-4">$79<span className="text-sm font-normal">USD/month</span></div>
                                <ul className="space-y-4 mb-6">
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 250 monthly copilot prompts</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Earnings reports since 2009</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Transcripts since 2021</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Live transcripts with Pinned Questions</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 10 Workflow executions per month</li>
                                    <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Feature/Data source requests</li>
                                </ul>
                            </div>
                            <button onClick={() => navigate('/signup?redirect=checkout')} disabled className="w-full bg-orange-300 text-white py-2 rounded-lg mt-6">Buy plan</button>
                        </div>
                    </div>
                </div>
            </MainBackground>
        </>
    )
}
