import React, { useEffect } from "react";
import { ArrowTopRightOnSquareIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";

interface ImageModalProps {
    imgName?: string;
    isOpen: boolean;
    imageSrc: string | null;
    onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imgName, isOpen, imageSrc, onClose }) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose(); // Close the modal when Escape is pressed
            }
        };

        if (isOpen) {
            window.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, onClose]);

    if (!isOpen || !imageSrc) return null;

    const handleSaveImage = () => {
        const link = document.createElement("a");
        link.href = imageSrc;
        link.download = imgName || "image.png"; // Default filename
        link.click();
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
            onClick={onClose}
        >
            <div
                className="relative max-w-full max-h-full"
                onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside
            >
                <img
                    src={imageSrc}
                    alt="Enlarged View"
                    className="max-w-[90vw] max-h-[90vh] object-contain"
                />
                {/* Close Button */}
                <button
                    className="absolute top-4 right-4 bg-black bg-opacity-50 text-white text-xl font-bold w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={onClose}
                >
                    ✕
                </button>
                {!isOpen && (
                    <>

                        {/* Enlarge Button */}
                        < button
                            className="absolute bottom-4 right-20 bg-black bg-opacity-50 text-white flex items-center justify-center p-2 rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => window.open(imageSrc, "_blank")}
                        >
                            <ArrowTopRightOnSquareIcon className="w-6 h-6" />
                        </button>
                        {/* Save Image Button */}
                        <button
                            className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white flex items-center justify-center p-2 rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={handleSaveImage}
                        >
                            <ArrowDownTrayIcon className="w-6 h-6" />
                        </button>
                    </>
                )}
            </div>
        </div >
    );
};

export default ImageModal;

export interface ImgModalState {
    isImageModalOpen: boolean;
    modalImageSrc: string;
    fileName?: string;
}

